import { Pipe, PipeTransform, inject } from '@angular/core';
import { map } from 'rxjs';
import { Product } from '@app/store/models';
import { UserFacade } from '@app/store/user';

@Pipe({
    name: 'productName$',
})
export class ProductNamePipe implements PipeTransform {
    private readonly userFacade = inject(UserFacade);

    transform(product: Product) {
        return this.userFacade.features$.pipe(
            map((features) => {
                if (features.includes('products.separate-name-with-space')) {
                    return `${product.description} ${product.description2}`;
                }

                return `${product.description}${product.description2}`;
            }),
        );
    }
}
