import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductNamePipe } from './pipes/product-name.pipe';

@NgModule({
    declarations: [ProductNamePipe],
    imports: [CommonModule],
    exports: [ProductNamePipe],
})
export class ProductNamePipeModule {}
